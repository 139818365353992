import OpenAI from 'openai';
const openai = new OpenAI({
    //organization: process.env.REACT_APP_OPENAI_ORGANIZATION
    apiKey: process.env.REACT_APP_OPENAI_API_KEY
});

export async function headerFromSubject(subject){
    const prompt = "Generate a title, subtitle, date, and location for a newspaper article about " + subject + ".";
    const request = {
        model: "gpt-3.5-turbo",
        messages: [{ "role": "user", "content": prompt}]
    };
    const response = await openai.chat.completions.create(request);
    return response.choices[0].message.content;
}

export async function bodyFromHeader(header){
    const prompt = "Complete a newspaper article with the following header:\n\n " + header + "\n\nBody:";
    const request = {
        model: "gpt-3.5-turbo",
        messages: [{ "role": "user", "content": prompt}]
    };
    const response = await openai.chat.completions.create(request);
    return response.choices[0].message.content;
}