import './App.css';
import { NewspaperArticle } from './loremipsum.js';

function randomChoice(arr) {
  return arr[Math.floor(arr.length * Math.random())];
}

function App() {
  const examplePrompts = [
    "black mass gone awry",
    "snakes in the soup pipes",
    "the sky is getting too big",
    "gerbil commune faces twitter controversy",
    "the ongoing furby shortage",
    "bugmen invade Constantinople",
    "coffee spill in Suez Canal"

  ];
  return NewspaperArticle({
    initialSubject: randomChoice(examplePrompts)
  });
}

export default App;
