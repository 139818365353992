import "./loremipsum.css"
import { useState } from "react";
import { isMobile } from "react-device-detect";
const newspaper = require("./newspaperPrompts.js");

String.prototype.format = (args) => {
    var a = this;
    for (var k in args) {
      a = a.replace("{" + k + "}", args[k])
    }
    return a
}

async function queryOpenAI(articleSubject, setArticleTitle, setArticleContent, callback){
    const header = await newspaper.headerFromSubject(articleSubject);
    const title = header.split('\n')[0].slice(8, -1).trim();
    setArticleTitle(title);

    const body = await newspaper.bodyFromHeader(header);
    setArticleContent(body);

    callback();
}

function InstructBox({articleSubject, setArticleSubject, setArticleTitle, setArticleContent}){
    const [clicked, setClicked] = useState(false);

    if (clicked) {
        return <div className="instruct-box">
            <p>Loading content for subject...</p>
            <div className="user-control">
                <p>{articleSubject}</p>
            </div>
        </div>
    }
    return <div className= "instruct-box">
        <p>Insert subject below</p>
        <div className="user-control">
            <textarea
                name="promptInput"
                value={articleSubject}
                onChange={e => setArticleSubject(e.target.value)}
            />
            <div className="button-container">
                <button type="button"
                        className="get-article"
                        onClick={() => {
                            setClicked(true);
                            queryOpenAI(articleSubject,
                                        setArticleTitle,
                                        setArticleContent,
                                        () => setClicked(false));
                        }}>
                    Go
                </button>
            </div>
        </div>
    </div>
}

function NewspaperBody({articleTitle, articleContent}){
  if (isMobile){
    return <div className="row-mobile">
      <div className="column-mobile">
        <div className='article-title'>{articleTitle}</div>
        <p align="justify">{articleContent}</p>
      </div>
    </div>

  } else {
    // We expect between 2000 and 4000 characters,
    // We expect between 400 and 600 words.
    // To parse the words into 3 columns, we put the first 200 words
    // in column 1, then next 200 in column 2, and the remaning in column 3.

    const words = articleContent.split(' ');
    const maxWords = 450;
    const endTag = (words.length > maxWords) ? '...' : '';
    const columns = [words.slice(0, 100).join(' '),
                     words.slice(100, 280).join(' '),
                     words.slice(280, maxWords).join(' ') + endTag];
 
    return <div className='row-desktop'>
        <div className="column-desktop">
            <div className='article-title'>{articleTitle}</div>
            <p align="justify">{columns[0]}</p>
        </div> 
        <div className="column-desktop"><p align= "justify">{columns[1]}</p></div>
        <div className="column-desktop"><p align= "justify">{columns[2]}</p></div>
    </div>
  }
}

export function NewspaperArticle({initialSubject}){
    const [articleSubject, setArticleSubject] = useState(initialSubject);
    const [articleContent, setArticleContent] = useState("");
    const [articleTitle, setArticleTitle] = useState("");
    
    return <div className="page">  
        <div className='header'>
            <div className="bar1"></div>
            <div className="title">The Post Post</div>
            <div className="bar1"></div>
            <div className="header-items">
                <p>Austin, TX</p>
                <p>July 24, 2023</p>
                <p>lulenore.com</p>
            </div>
            <div className="bar2"></div>
        </div>
        
        <NewspaperBody articleTitle={articleTitle}
                       articleContent={articleContent}/>

        <div className="footer">
            <div className="bar2"></div>
            <div className="instruct-box-container">
                <InstructBox articleSubject={articleSubject}
                             setArticleSubject={setArticleSubject}
                             setArticleTitle={setArticleTitle}
                             setArticleContent={setArticleContent}
                             query={queryOpenAI}/>
            </div>
        </div>
    </div>
}
